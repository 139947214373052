import React from 'react';
import {Modal, Image} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

class Downloads extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            show:props.show,
        };

    }
    componentWillReceiveProps(nextProps){
        this.setState({show: nextProps.show})
    }

    close = () => {
        this.setState({show : false});
    }

    render(){
        if(!this.state.show) {
            return null;
        }
        return(
            <Modal show={this.state.show} size='xl' onHide={this.close} >
            <Modal.Header closeButton>
            <Modal.Title>Downloads</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="source-text">
		    A lesson Powerpoint is available <a href="All-the-Kings-Men.pptx" target="_blank">here</a> if you wish to use it.<br/><br/>
                    All case studies can be downloaded and printed double sided which gives 2 per page. 
                    click on the image below to download the studies
                    <br/><br/>
                    <a href="casestudies.pdf" target="_blank">
                        <img src="casestudies.png" width="300px" class="rounded mx-auto d-block" alt="Download pdf" />
                    </a>

                </div>
                
            </Modal.Body>
            <Modal.Footer>
          <Button variant="secondary" onClick={this.close} >
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
     
        )
        

    }
}
export default Downloads
