import React from 'react';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

class Howto extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            show:props.show,
        };

    }
    componentWillReceiveProps(nextProps){
        this.setState({show: nextProps.show})
    }

    close = () => {
        this.setState({show : false});
    }

    render(){
        if(!this.state.show) {
            return null;
        }
        return(
            <Modal show={this.state.show} size='lg' onHide={this.close} >
            <Modal.Header closeButton>
            <Modal.Title>Welcome</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="source-text">
                    
                    <p>
                        This interactive map displays information about the sometimes forgotten heroes from the British Empire during the First World War. 

                    </p>
                <p>
                To see a list of case studies for a country, click on an orange circle on the map
                </p>
                <p>
                When the list is displayed, click on a person's name to see detailed information about them.              </p>
                
                <p>Click the close buttons to return to the map</p>
               
                </div>
                
            </Modal.Body>
            <Modal.Footer>
          <Button variant="secondary" onClick={this.close} >
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
     
        )
        

    }
}
export default Howto