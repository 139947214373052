import React from 'react';
import {Modal} from 'react-bootstrap';
import {Button, Table} from 'react-bootstrap';

class Notes extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            show:props.show,
        };

    }
    componentWillReceiveProps(nextProps){
        this.setState({show: nextProps.show})
    }

    close = () => {
        this.setState({show : false});
    }

    render(){
        if(!this.state.show) {
            return null;
        }
        return(
            <Modal show={this.state.show} size='xl' onHide={this.close} >
            <Modal.Header closeButton>
            <Modal.Title>Teachers' Notes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="source-text">
                <p>The following tables provide a detailed summary of the key features of and similarities between the different case studies:
                </p>
                <h3>Country of origin</h3>				 	 			
                <Table responsive>
                    <thead>
                        <tr>
                        <th>England</th>
                        <th>Scotland</th>
                        <th>Wales</th>
                        <th>Ireland</th>
                        <th>Canada</th>
                        <th>Australia and New Zealand</th>
                        <th>Africa</th>
                        <th>India</th>
                        <th>West Indies and Guiana</th>
                        <th>China</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Reginald Bond, Florence Simpson, Frank de Pass, Siegfried Sassoon, Lionel Turpin, Walter Tull</td>
                        <td>William Henderson Walker, Charles Alfred Jarvis</td>
                        <td>Rupert Hallowes, Hubert Rees, Joseph Egerton</td>
                        <td>Emmett Dalton, Tom Kettle, James Crozier, Basil Maclear, Father Doyle</td>
                        <td>Mike Mountain Horse, Albert Mountain Horse, Buckam Singh, </td>
                        <td>John Woods Whittle, the Allen Brothers, John Monash, James Martin, William Sanders</td>
                        <td>William Frederick Faulds, Alhaji Grunshi</td>
                        <td>Khudadad Khan, Pratap Singh, Mir Mast, Mir Dast, </td>
                        <td>Eugent Clarke, Reginald Collins, </td>
                        <td>The Busseboom 13</td>
                        </tr>
                        
                    </tbody>
                </Table>
                <h3>Reason for enlisting</h3>
                <Table responsive>
                    <thead>
                        <tr>
                        <th>Patriotism</th>
                        <th>Adventure</th>
                        <th>Career Soldiers</th>
                        <th>Vengeance</th>
                        <th>Interest in military</th>
                        <th>Poverty/ desire for work
                        </th>
                        <th>Loyalty to others</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>James Crozier, Siegfried Sassoon, Florence Simpson, Eugent Clarke, Pratap Singh, Reginald Collins, Lionel Turpin, Charles Jarvis
Tom Kettle [for Ireland]
                        </td>
                        <td>Eugent Clarke
                        </td>
                        <td>Albert Mountain Horse, Mir Dast, Frank de Pass, Mir Mast, Reginald Bond, Basil Maclear, Pratap Singh, John Monash, Hubert Rees, Alhaji Grunshi, John Woods Whittle
                        </td>
                        <td>Mike Mountain Horse</td>
                        <td>Joseph Egerton, James Martin, Rupert Hallowes</td>
                        <td>Buckam Singh, Eugent Clarke, James Crozier, Busseboom 13</td>
                        <td>Walter Tull, Siegfried Sassoon</td>
  
                        
                        </tr>
                        
                    </tbody>
                </Table>
                <h3>Fighting Fronts</h3>
                <Table responsive>
                    <thead>
                        <tr>
                        <th>Western Front</th>
                        <th>Middle East</th>
                        <th>Africa</th>
                        <th>Greece</th>
                        <th>Italy</th>
                        <th>Gallipoli</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Florence Simpson, Eugent Clarke, Busseboom 13, Albert Mountain Horse, Frank de Pass, Mir Mast, John Monash, William Faulds, Joseph Egerton, Buckam Singh, Father Doyle, Reginald Collins, Basil Maclear, Emmett Dalton, Mir Dast, Siegfried Sassoon, Pratap Singh, Lionel Turpin, Khudadad Khan, Tom Kettle, Mike Mountain Horse, William Henderson Walker, The Allen Brothers, Walter Tull, Hubert Rees, Rupert Hallowes, Charles Jarvis</td>
                            <td>Emmett Dalton, Siegfried Sassoon, Reginald Collins, Pratap Singh, William Henderson Walker, Joseph Egerton</td>
                            <td>John Monash, William Faulds, Alhaji Grunshi</td>
                            <td>Emmett Dalton, William Henderson Walker</td>
                            <td>Reginald Collins, Walter Tull</td>
                            <td>James Martin, William Henderson Walker</td>
                    
                        
                        </tr>
                        
                    </tbody>
                </Table>
                <h3>Impact of Injuries
</h3>
                <Table responsive>
                    <thead>
                        <tr>
                        <th>Killed in action / died of injuries/ illness</th>
                        <th>Wounded and returned to fight</th>
                        <th>Survived the war</th>
                      
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>The Allen brothers, Albert Mountain Horse, Rupert Hallowes, Busseboom 13, Buckam Singh, Frank de Pass, Tom Kettle, Walter Tull, Father Doyle, James Martin, Basil Maclear, James Crozier [executed], Lionel Turpin [in 1929], William Sanders</td>
                            <td>Hubert Rees, Emmett Dalton, Siegfried Sassoon, William Henderson Walker, Walter Tull, Mir Dast, Khudadad Khan</td>
                            <td>Alhaji Grunshi, Mir Dast, Pratap Singh, Khudadad Khan, Mike Mountain Horse, Reginald Collins, Eugent Clarke, Alhaji Grunshi, William Faulds, Lionel Turpin, Siegfried Sassoon, Florence Simpson, Reginald Bond, John Monash, John Woods Whittle, Emmett Dalton, Joseph Egerton, Hubert Rees, Charles Jarvis, William Henderson Walker</td>
                        
                        </tr>
                        
                    </tbody>
                </Table>
                <h3>Type of Injury
</h3>
                <Table responsive>
                    <thead>
                        <tr>
                        <th>Gas attack wound</th>
                        <th>Bayonet wound</th>
                        <th>Sniper wound</th>
                        <th>Shell shock </th>
                        <th>Shelling wound</th>
                        <th>Disease</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Lionel Turpin, Mir Dast, Joseph Egerton, Albert Mountain Horse</td>
                            <td>Mike Mountain Horse</td>
                            <td>Frank de Pass, Siegfried Sassoon</td>
                            <td>Siegfried Sassoon, Walter Tull</td>
                            <td>Mike Mountain Horse, The Allen Brothers, Father Doyle, William Sanders</td>
                            <td>Eugent Clarke (frostbite), Spanish Flue (Mir Mast), Buckam Singh (TB), Albert Mountain Horse (TB), Emmett Dalton (Malaria), William Henderson Walker (Malaria), James Martin (Typhoid)</td>
                        
                        </tr>
                        
                    </tbody>
                </Table>
                <h3>Degree of reward</h3>
                <Table responsive>
                    <thead>
                        <tr>
                        <th>Promotion during war</th>
                        <th>Faced discrimination</th>
                        <th>Won a Victoria Cross</th>
                        <th>Won a DCM or another medal</th>
                        <th>Treated as a war hero</th>
                   
                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                            John Woods Whittle, Walter Tull, Rupert Hallowes, Emmett Dalton, Reginald Collins, William Faulds, John Monash
                            </td>
                            <td>Walter Tull, Reginald Collins, Eugent Clarke, Father Doyle, Busseboom 13</td>
                            <td>Rupert Hallowes, Khudadad Khan, Frank de Pass, Mir Dast, Charles Jarvis, William Faulds, John Woods Whittle, William Sanders</td>
                            <td>Lionel Turpin, Father Doyle, Mike Mountain Horse, Alhaji Grunshi, Hubert Rees, Siegfried Sassoon, Eugent Clarke, Florence Simpson</td>
                            <td>Albert Mountain Horse, Mike Mountain Horse, John Monash, Eugent Clarke, Lionel Turpin, Father Doyle</td>
                            
                        </tr>
                        
                    </tbody>
                </Table>
                <h3>Treatment of Deserters
</h3>
                <Table responsive>
                    <thead>
                        <tr>
                        <th>Executed by firing squad</th>
                        <th>Hushed up by government</th>
                        <th>No punishment</th>
                        <th>Deemed mentally ill</th>
               
                   
                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>James Crozier</td>
                            <td>Siegfried Sassoon, Mir Dast</td>
                            <td>Mir Mast</td>
                            <td>Siegfried Sassoon</td>
                            
                        </tr>
                        
                    </tbody>
                </Table>
                <h3>Other
</h3>
                <Table responsive>
                    <thead>
                        <tr>
                        <th>Old</th>
                        <th>Young</th>
                        <th>No Known Grave</th>
                        <th>Taken Prisoner</th>
                        <th>Served / joined up with family, friends or colleagues</th>
               
                   
                        
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Pratap Singh (73)</td>
                            <td>Emmett Dalton (17), James Crozier (17), Eugent Clarke (18), James Martin (14)</td>
                            <td>Walter Tull, James Martin, Basil Maclear, The Allen Brothers, William Sanders</td>
                            <td>Reginald Bond, Hubert Rees, William Faulds</td>
                            <td>Walter Tull, The Allen Brothers, Pratap Singh</td>
                            
                        </tr>
                        
                    </tbody>
                </Table>
                
                <h3>Contextual Information</h3>
<p>
When war broke out in 1914, men from across Britain’s colonies and dominions were keen to enlist.  There were a range of motivating factors behind this.  Certainly a desire to serve King and Empire seems to have been prevalent in some cases in all countries.  At times [for example with Tom Kettle], the desire to serve was more about serving and protecting a home country as opposed to the empire yet was still clearly due to nationalistic feeling.  Other key reasons for enlisting seem to have been the desire for a better wage, especially where unemployment was an issue at home, an interest in the military [including those who were already soldiers in 1914] and a desire for adventure. In the case of some soldiers, such as those in the West Indies, prejudiced attitudes about black men fighting meant that they were not initially allowed to enlist and it was their persistence coupled with a need for more men that led to a shift in the rules.  There is clear evidence of some of these prejudiced attitudes persisting throughout the war, for example in the discriminatory treatment of troops from the West Indies after the Armistice and the restrictions faced by members of the Chinese Labour Corps.  Yet, despite the highly prejudiced King’s Regulations which did not allow black officers, examples such as that of Walter Tull being promoted to officer rank show that there was sometimes a shift in attitude from those in charge.  1916 and the Battle of the Somme seems to have been a key year in bringing about some slackening of the rules.  The need for more soldiers after the devastating losses of the Somme can clearly be seen through the case studies to have led senior officials to agree to the promotion of some black soldiers, the enlistment of Chinese labourers and the use of female auxiliary workers at the Front.
</p>
<p>
In order to contextualise the case studies, it is useful to consider the statistics regarding British Imperial troops, and thus these have been set out below:
</p>
<ul>
<li>In total, around 1/3 of British forces in the First World War were non-white</li>
<li>Around 12% of British forces were killed in action.  A majority returned home after the war</li>
<li>Over 620 000 soldiers enlisted voluntarily or were conscripted to fight for Britain from Canada.  Of these, over 424 000 served overseas, over 1200 were killed and 2500 wounded</li>
<li>The British West Indies Regiment was formed in September 1915.  There were around 15 500 volunteers, 2/3 from Jamaica.</li>
<li>Over 700 men enlisted from British Guiana</li>
<li>There were over 55 000 combat soldiers and more auxiliary troops from Africa.  Many saw active service in the campaigns to capture the German colonies of Togo, Cameroon and German West and East Africa.  Approximately 10 000 were killed and 166 men won medals.</li>
<li>There were over 1 million recruits from India, 140 000  of whom served on the Western Front.  70 000 served in the Middle East. Over 47 000 men were killed or missing in action</li>
<li>Out of a population of 5 million, 416 809 men enlisted to fight from Australia and New Zealand.  Over 60 000 were killed in action.  Just under 10% of New Zealand’s total population served, which was proportionately more than any other country fighting for Britain.  Nearly one in five of these New Zealanders who served overseas did not return.</li>
<li>200 000 Gurkhas were recruited from Nepal</li>
<li>China, Hong Kong and Singapore sent auxiliary troops.  At least 1000 died</li>
<li>There were 733514 Englishmen serving in the British Army at the outbreak of war and these forces were bolstered with 4 006 158 recruits from England, 557 618 from Scotland, 272 924 from Wales and around 140 000 from Ireland.</li>
</ul>
                </div>
                
            </Modal.Body>
            <Modal.Footer>
          <Button variant="secondary" onClick={this.close} >
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
     
        )
        

    }
}
export default Notes
