import React from 'react';
import {Modal} from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import SourceModal from './SourceModal'

class SourceList extends React.Component{


    remoteURL = 'http://api.historyhive.co.uk';

    constructor(props){
        super(props);
        this.state = {
            source:[],
            show:props.show,
            showSource:false,
            sourceId:0
        };

        this.close = this.close.bind(this);
        this.openSource = this.openSource.bind(this)
    }
    componentWillReceiveProps(nextProps){
        this.setState({source:[],show: nextProps.show, showSource:false, sourceId:0});
    
        fetch(this.remoteURL + '/sources', {
            
        })
        .then(results => {
            return results.json();
        })
        .then(data => {
            

            var items = data.filter((item) =>
                item.country && (item.country.Name == this.props.countryName)   
            );
            this.setState({source: items});

        })
    }
    close = () => {
        this.setState({show : false});
       // this.render();
    }
    openSource = (id) => {

        this.setState({showSource:true, sourceId:id})

    }

    getImage(imageName){
        let image = '';
        if(this.state.source[imageName]){
            image = this.remoteURL + this.state.source[imageName].url;
        }
        return image;
    }    
    

    render() {
        
        if(!this.state.show) {
            return null;
          }
      
        let items = '';
   
        items = this.state.source.map((titles,i) =>
                 <li className='list-group-item' key={i} onClick={() => this.openSource(titles.id)}>{titles.Title} {titles.SubCountry} </li>
                );
   
        return(

            <>
     
      <Modal show={this.state.show} onHide={this.close}>
        <Modal.Header closeButton>
          <Modal.Title>Case Studies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="well source-text">
                
                    {this.props.statistics}
            
            </div>
            <br/>
            <br/>
    
            <div className="source-list source-bullets">
                    <ul className="list-group">
                        {items}
                    </ul>
            </div>
            
            </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.close} >
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
      <SourceModal show={this.state.showSource}
        sourceId={this.state.sourceId}
            ></SourceModal>
    </>
            
        );
    }
}
export default SourceList;