import React from 'react';

class Source extends React.Component{

    remoteURL = 'http://api.historyhive.co.uk';

    constructor(props){
        super(props);
        this.state = {
            source:{},
        };
    }

    componentDidMount() {
        fetch(this.remoteURL + '/sources/' + this.props.sourceId,{
            
        })
        .then(results => {
            return results.json();
        })
        .then(data => {
            this.setState({source: data});
        })
    }

    getImage(imageName){
        let image = '';
        if(this.state.source[imageName]){
            image = this.remoteURL + this.state.source[imageName].url;
        }
        return image;
    }    

    render() {

        let items = '';
        if(this.state.source.bullets){

            items = this.state.source.bullets.map((bullet,i) =>
                 <li key={i} className='list-group-item'>{bullet.Bullet}</li>
                );
        }
        
        return(
        <div className="source">
            <div className='source-heading row'>
                <div className="col-12 heading-title">
                    <span className="align-middle">{this.state.source.Title}</span>
                </div>
            </div>
            <div className="source-intro row">
            <div className="col-4">
                <div className="source-mainImage">
                    <img src={this.getImage('MainImage')} className='img-fluid'></img>
                </div>
                </div>
            <div className="col-8">
                <div className="source-bullets">
                    <ul className="list-group">
                        {items}
                    </ul>
                </div>
                </div>
                
            </div>
            <div className="source-content row">
                <div className="source-text col-12">
                    <br/><br/>
                    {this.state.source.Content}
                </div>
            </div>
            <div className="images row">
                <div className="source-images col-4">
                    <img src={this.getImage('Image3')} />
                </div>
                <div className="source-images col-4">
                    <img src={this.getImage('Image4')} />
                    </div>
                <div className="source-images col-4">
                    <img src={this.getImage('Image5')} />
                </div>
            </div>
        </div> 
        );
    }
}
export default Source;