import React from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap';
import Downloads from './Downloads';
import Notes from './Notes';
import About from './About';
import Howto from './Howto';

class Navig extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            showAbout:false,
            showNotes: false,
            showDownloads: false,
            showHowto:true
        }
        this.showAbout = this.showAbout.bind(this)
        this.showNotes = this.showNotes.bind(this)
        this.showDownloads = this.showDownloads.bind(this)
    }

    showHowto(){
        this.setState({showHowto: true,showNotes:false, showDownloads:false, showAbout:false})
    }
    showAbout(){
        this.setState({showNotes:false, showDownloads:false, showAbout:true,showHowto: false})
    }
  
    showNotes(){
        this.setState({showDownloads:false, showAbout:false, showNotes:true,showHowto: false})
    }
  
    showDownloads(){
        this.setState({showAbout:false, showNotes: false,showDownloads:true,showHowto: false})
    }

    render(){
        return(
            <div>
        <Navbar bg="dark" expand="lg" variant="dark">
            <Navbar.Brand >All The King's Men</Navbar.Brand>
            <span className="sub-text">How much did the British Empire's first world war forces have in common?</span>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link onClick={this.showAbout}>About</Nav.Link>
                    <Nav.Link onClick={this.showNotes}>Teachers Notes</Nav.Link>
                    <Nav.Link onClick={this.showDownloads}>Downloads</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        <About show={this.state.showAbout} ></About>
        <Downloads show={this.state.showDownloads}></Downloads>
        <Notes show={this.state.showNotes}></Notes>
        <Howto show={this.state.showHowto}></Howto>
        </div>
        )
    }
}

export default Navig;
  