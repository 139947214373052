import React from 'react';
import {Modal} from 'react-bootstrap'
import {Button} from 'react-bootstrap'

import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Markers,
    Marker,
  } from "react-simple-maps"
import SourceList from './SourceList';
  
  const wrapperStyles = {
    width: "100%",
    maxWidth: 980,
    margin: "0 auto",
  }
  
  const include = [
    "ARG", "BOL", "BRA", "CHL", "COL", "ECU",
    "GUY", "PRY", "PER", "SUR", "URY", "VEN",
  ]
  
  var markers = [
    {
      markerOffset: -25,
      name: "Canada",
      coordinates: [
        -107.971496,
        52.22107
        
      ]
    },
    { markerOffset: -25, name: "Buenos Aires", coordinates: [-58.3816, -34.6037] },
    { markerOffset: -25, name: "La Paz", coordinates: [-68.1193, -16.4897] },
    { markerOffset: 35, name: "Brasilia", coordinates: [-47.8825, -15.7942] },
    { markerOffset: 35, name: "Santiago", coordinates: [-70.6693, -33.4489] },
    { markerOffset: 35, name: "Bogota", coordinates: [-74.0721, 4.7110] },
    { markerOffset: 35, name: "Quito", coordinates: [-78.4678, -0.1807] },
    { markerOffset: -25, name: "Georgetown", coordinates: [-58.1551, 6.8013] },
    { markerOffset: -25, name: "Asuncion", coordinates: [-57.5759, -25.2637] },
    { markerOffset: 35, name: "Paramaribo", coordinates: [-55.2038, 5.8520] },
    { markerOffset: 35, name: "Montevideo", coordinates: [-56.1645, -34.9011] },
    { markerOffset: -25, name: "Caracas", coordinates: [-66.9036, 10.4806] },
  ]
class Map extends React.Component{
  remoteURL = 'http://api.historyhive.co.uk';
  constructor(props){
    super(props)
    this.showSources = this.showSources.bind();
    this.handleMarkerClick = this.handleMarkerClick.bind();
    this.state = {
      countries:[],
      isOpen: false,
      country: '',
      statistics: ''
    };
  }

  componentDidMount(){
    
    fetch(this.remoteURL + '/countries',{
            
    })
    .then(results => {
        return results.json();
    })
    .then(data => {
        this.setState({countries: data});
    })
  }

  showSources(){
    

  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" >
        Launch demo modal
      </Button>

      <Modal >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" >
            Close
          </Button>
          <Button variant="primary" >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  }

  handleMarkerClick = (marker, evt) =>{
    const x = evt.clientX
    const y = evt.clientY + window.pageYOffset
    this.setState({ statistics: marker.statistics, isOpen: true, country: marker.name});
  }

    render() {

      if(this.state.countries){
        markers = this.state.countries.map((coords) =>
            coords.Coordinates  
        );
        let country = {}
        let that = this;

        markers.forEach(function(marker){
            country = that.state.countries.find((country) =>
              country.Name == marker.name
            );

            marker.statistics = country.Statistics;
        });

    
      }
        return (
          <div style={wrapperStyles}>
            <ComposableMap
              projectionConfig={{
                scale: 205,
                rotation: [-11,0,0],
              }}
              width={980}
              height={551}
              style={{
                width: "100%",
                height: "auto",
              }}
              >
              <ZoomableGroup center={[0,20]} disablePanning>
                <Geographies geography="world-50m.json">
                  {(geographies, projection) => geographies.map((geography, i) => geography.id !== "ATA" && (
                    <Geography
                      key={i}
                      geography={geography}
                      projection={projection}
                      onClick={this.handleClick}
                      style={{
                        default: {
                          fill: "#ECEFF1",
                          stroke: "#607D8B",
                          strokeWidth: 0.75,
                          outline: "none",
                        },
                        hover: {
                          fill: "#607D8B",
                          stroke: "#607D8B",
                          strokeWidth: 0.75,
                          outline: "none",
                        },
                        pressed: {
                          fill: "#FF5722",
                          stroke: "#607D8B",
                          strokeWidth: 0.75,
                          outline: "none",
                        },
                      }}
                    />
                  ))}
                </Geographies>
                <Markers>
              {markers.map((marker, i) => (
                <Marker
                  key={i}
                  marker={marker}
                  style={{
                    default: { fill: "#FF5722" },
                    hover: { fill: "#FFFFFF" },
                    pressed: { fill: "#FF5722" },
                  }}
                  onClick={this.handleMarkerClick}
                  >
                  <circle
                    cx={0}
                    cy={0}
                    r={10}
                    style={{
                      stroke: "#FF5722",
                      strokeWidth: 3,
                      opacity: 0.9,
                    }}
                  />
                  <text
                    textAnchor="middle"
                    y={marker.markerOffset}
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      fill: "#607D8B",
                    }}
                    >
                    {marker.name}
                  </text>
                </Marker>
              ))}
            </Markers>
              </ZoomableGroup>
            </ComposableMap>
            <SourceList show={this.state.isOpen}
              countryName={this.state.country} 
              statistics={this.state.statistics}   
            >
          </SourceList>
          </div>
          
        )
        
      }
    }
  
  export default Map
