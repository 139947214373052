import React from 'react';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

class About extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            show:props.show,
        };

    }
    componentWillReceiveProps(nextProps){
        this.setState({show: nextProps.show})
    }

    close = () => {
        this.setState({show : false});
    }

    render(){
        if(!this.state.show) {
            return null;
        }
        return(
            <Modal show={this.state.show} size='xl' onHide={this.close} >
            <Modal.Header closeButton>
            <Modal.Title>About</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="source-text">
                <p>
                The 2018 Royal Historical Society report on ‘Race, Ethnicity and Equality in UK History Teaching highlighted a number of concerning issues relating to racial bias within the discipline.  Its finding that BAME students and staff are underrepresented in UK HE History Departments, and suggestion that one reason for this may be due to a predominately ‘White-centred and Eurocentric curriculum’ is of real concern.  The report also highlights a growing movement for change, both in terms of academic historians and organisations researching areas of underrepresented histories and through schools and universities working to diversify their history curricula. All the King’s Men seeks to reflect this movement towards greater inclusivity, and indeed accuracy, in the history curriculum with a focus on the First World War.  Research by Dr. Catriona Pennell into current UK practice shows that ‘a history of the war is being taught that focuses primarily on the trenches of the Western Front alongside diplomatic questions of causation and origin’ and that this is leading to the ‘exclusion of other narratives (for example the global reach of the war and colonial and non-combatant experiences)’.  All the King’s Men addresses some of these alternative narratives of war experience alongside the more traditional Western Front history, thus widening the lens through which students view the First World War and the background and experiences of those who fought in it.  
                </p>
                <p>
                The resource consists of a set of 33 case studies, each of which addresses the story of one soldier who fought for the British empire.  The soldiers featured originate from across the empire and dominions, with case studies including examples of men from England, Scotland, Wales, Ireland [both Unionists and Republicans], Canada, Australia, New Zealand, Africa, the West Indies and China.  Each case study contains information about a soldier’s country of origin, occupation before the war, when [and in most cases why] they enlisted, where they fought, what happened to them and [if they survived the war] what happened after the war ended.  All of the case studies featured on this website can be downloaded for printing if desired.  The printable case studies are designed to be A5 and double sided.  The front side shows a picture of the soldier where it has been possible to source one and contains a simplified bullet-point summary of key aspects of their story.  On the other side of the card there is a more detailed description, along with the maps showing the journeys that soldiers took. It is hoped that this will enable a range of students to access this resource.  The front of each case study is colour coded according to the country of origin of each soldier.
                </p>
                <p>
                The creation of this resource has been significantly aided by the plethora of information available about many countries’ fighting forces created in response to the centenary of the First World War.  There is much extremely useful material available online, for example the National Army Museum, the gov.uk site about Victoria Cross recipients, Edinburgh’s war, the Australian War Memorial site, the Ensuring we Remember site and the Hill 70 website.  Many of these sites contain detailed information about soldiers from specific nations.  However, most are created to focus only on men from one area or nation.  In creating my resource, I have tried to bring this information together to enable a comparative study of the experiences of a range of people of different backgrounds and nationalities fighting for the British Empire.
                </p>
                <p>
                The work of academic historians seeking to examine a more diverse history of the First World War has also been extremely useful. I was inspired by the work of David Olusoga, Santanu Das and Stephen Bourne when first researching the experiences of non-white soldiers.  Professor Richard Grayson was also extremely helpful and generous in advising me regarding case studies of Irish soldiers from both sides of the unionist / nationalist debate.  His books, Dublin’s Great Wars and Belfast Boys have been extremely useful in gathering information about the role of Irish men in the First World War.  
                </p>
                <p>
                One area of frustration has been in trying to include case studies relating to men in the Chinese Labour Corps. The work of Brian Fawcett relating to the Busseboom 13 has been very helpful in  providing names of the men who were killed as well as some general background detail.  However, it saddens me that I have been unable to discover enough detail about any of the men to complete personal and individual case studies about their experience.  
               </p>
                </div>
                
            </Modal.Body>
            <Modal.Footer>
          <Button variant="secondary" onClick={this.close} >
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
     
        )
        

    }
}
export default About