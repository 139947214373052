import React from 'react';
import Source from '../components/Source';
import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';

class SourceModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            show:props.show,
        };

    }
    componentWillReceiveProps(nextProps){
        this.setState({show: nextProps.show});

    }
    close = () => {
        this.setState({show : false});
        this.render();
    }
    render(){
        if(!this.state.show) {

            return null;
        }
        return(
            <Modal show={this.state.show} size='xl' onHide={this.close} >
        <Modal.Header closeButton>
          <Modal.Title>Case Study</Modal.Title>
        </Modal.Header>
        <Modal.Body><Source sourceId={this.props.sourceId} /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.close} >
            Close
          </Button>
          
        </Modal.Footer>
      </Modal>
     
        )
        

    }
}
export default SourceModal
